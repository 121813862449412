<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    :height="height"
    :width="width"
  >
    <defs>
      <linearGradient
        v-if="fullLogo"
        id="MyGradient"
        x1="0%"
        y1="0%"
        x2="180%"
        y2="0%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" :stop-color="color" />
        <stop v-if="progress" :offset="`${progress}%`" :stop-color="color" />
        <stop v-if="progress < 100" offset="100%" stop-color="black" />
      </linearGradient>
      <linearGradient v-if="!fullLogo" id="MyGradient">
        <stop offset="0" :stop-color="color" />
        <stop v-if="progress" offset="0" :stop-color="color" />
        <stop v-if="progress" :offset="`${progress}%`" :stop-color="color" />
        <stop v-if="progress < 100" offset="100%" stop-color="black" />
      </linearGradient>
    </defs>
    <path
      v-if="!fullLogo"
      fill="url(#MyGradient)"
      d="M415.83,154.08c-31.08-.13-50.17,12.62-69.29,36.63-12.73-24.91-34.44-37-60.08-37.14-24.39-.09-46.9,9.86-64.43,28.71L222,158.76l-51.67-.2-.41,105.63c-.16,4.19-.44,8.27-1,12.24-1.23,9.57-3.78,18.54-9.13,27a43.51,43.51,0,0,1-14.72,14.36,39.93,39.93,0,0,1-20.33,5.32c-31.46-.13-34.86-26.17-34.7-67l.38-97.51-51.67-.21-.55,141c-.17,44.29,32,70.83,70.43,71,2.11,0,4.17-.09,6.2-.25,20-1.52,36.74-10.78,54.73-25.79l-.08,20.46h.17v.38l51.67.2.42-108c2-37.88,20.08-56.6,44.34-56.5,28.74.11,36.42,24.62,36.26,64.63L302,365.36l51.67.21.4-101.79c.16-42.35,18.89-62.48,44.92-62.37,23.31.09,35.29,16.84,35.16,50.64l-.45,113.83h1.42l50.25.2.35-87.9.12-32.92c.11-27.2-1.36-49.74-14.89-66.88C459.31,163.57,438.75,154.16,415.83,154.08Z"
    />
    <g v-if="fullLogo" fill="url(#MyGradient)" id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M439.15,22.09C426.33,9.17,411.61,2.68,394.45,2.61c-37-.14-67.15,30.31-67.33,74.82-.17,43.7,31.35,74.93,67.55,75.07,18,.07,31.39-6.31,44-18.6l-.06,15,35.66.13.56-142.37-35.66-.14Zm-37.92,97.72C379,119.72,363,101.7,363.05,77.3c.09-23.33,14.91-42,38.51-41.95,22.52.09,39.08,17,39,42C440.44,103.34,423.21,119.9,401.23,119.81Z"
        />
        <path
          class="cls-1"
          d="M979.37,81.6C979.54,36.46,949,4.1,903.9,3.92,858,3.74,829.61,39.64,829.46,78.59c-.17,41.92,29,75.35,74.39,75.52,30.1.12,50.28-9.47,65.68-31.18l-30-14.35c-10,9.63-20.21,13.89-35.52,13.83-20.42-.08-36.5-12-39.91-33.2l115,.45C979.08,86.43,979.35,84.82,979.37,81.6ZM865.8,61c7.05-18,20.79-26.78,38.52-26.71,19.08.07,34.35,10.34,39.12,27Z"
        />
        <path
          class="cls-1"
          d="M260.62.35C239.17.26,226,9.06,212.8,25.63,204,8.44,189,.07,171.34,0,154.5-.07,139,6.8,126.87,19.81l-.05-16.23L91.16,3.44l-.29,72.9c-.11,2.9-.3,5.71-.65,8.46a44.28,44.28,0,0,1-6.31,18.62,30,30,0,0,1-10.15,9.91,27.54,27.54,0,0,1-14,3.67C38,116.92,35.67,98.94,35.78,70.79L36,3.49.38,3.35,0,100.68c-.12,30.56,22.06,48.88,48.61,49,1.45,0,2.87-.07,4.28-.17,13.82-1.05,25.35-7.44,37.77-17.8l-.06,14.12h.12v.26l35.66.14.29-74.57c1.39-26.14,13.86-39.06,30.6-39,19.84.07,25.14,17,25,44.6L182,146.17l35.66.14L218,76.06c.11-29.22,13-43.12,31-43.05,16.09.07,24.35,11.63,24.26,35l-.31,78.56h1l34.68.14L308.82,86l.09-22.72C309,44.51,308,29,298.63,17.12,290.63,6.9,276.44.41,260.62.35Z"
        />
        <path
          class="cls-1"
          d="M583.54,3.35c-1.45,0-2.87.07-4.28.17C565.4,4.57,553.83,11,541.38,21.42l0-14.48L505.77,6.8l-.48,124.94-.07,17.43,35.66.14.23-60.59c0-7.27,0-14.08.82-20.5a44.28,44.28,0,0,1,6.31-18.62,29.9,29.9,0,0,1,10.16-9.91,27.38,27.38,0,0,1,14-3.67c21.72.08,24.06,18.06,24,46.21l-.26,67.3,35.66.14.38-97.33C632.27,21.77,610.09,3.45,583.54,3.35Z"
        />
        <polygon
          class="cls-1"
          points="790.11 7.2 718.13 6.92 718.01 37.27 772.55 37.49 707.63 149.54 823.43 149.99 823.55 119.64 762.83 119.4 827.48 7.34 790.11 7.2"
        />
        <polygon
          class="cls-1"
          points="655.67 37.49 691.41 37.49 691.52 6.98 655.78 6.82 655.67 37.49"
        />
        <polygon
          class="cls-1"
          points="655.23 147.95 690.96 148.09 691.39 45.33 655.65 45.33 655.23 147.95"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import ProgressUtil from '@/helpers/progress/progress.helper';

export default {
  name: 'ProgressSVG',
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0,
    },
    height: {
      type: String,
      required: false,
      default: '80',
    },
    width: {
      type: String,
      required: false,
      default: '80',
    },
    fullLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    color() {
      return ProgressUtil.getProgressColor(this.progress, 240);
    },
    viewBox() {
      return this.fullLogo ? '0 -180 524 524' : '0 0 524 524';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
</style>
